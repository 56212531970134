





































































import { Component, Vue } from "vue-property-decorator";
import {
  mdiAccount,
  mdiBadgeAccountHorizontal as mdiBadge,
  mdiChartBox as mdiChart,
} from "@mdi/js";

// Utilities
import { UserModule } from "@store/modules";
import { User } from "@typings/user";

@Component
export default class Profile extends Vue {
  avatarSize = 80;
  icons = { mdiAccount, mdiBadge, mdiChart };

  /** User profile */
  get profile(): User | null {
    return UserModule.user;
  }
}
